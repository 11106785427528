import React, { useEffect, useState, useRef } from "react";
import CheckoutProduct from "./CheckoutProduct";
import { useStateValue } from "./StateProvider";
//import { Link } from "react-router-dom";
import "./Payment.css";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Table, TableBody } from "@mui/material";

import CurrencyFormat from "react-currency-format";
import { getBasketTotal } from "./reducer";
import axios from "./axios"; // we pull from our local file axios
import { Link, useNavigate } from "react-router-dom";
import { db } from "./firebase";
import { doc, setDoc, getDocs } from "firebase/firestore";
import AddNew from "./AddNew";
import qs from "qs";
import "./EmailForm.css";
import ReactGA from "react-ga4";

function Payment(event) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState([]);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState();
  const [disabled, setDisabled] = useState(true);
  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState("");
  const [clientSecret, setClientSecret] = useState(true); // necessary before requesting Stripe to process
  const [{ basket, user }, dispatch] = useStateValue();
  // EMAIL

  // DELIVERY ADDRESS VARIABLES
  const [paymentIntentDeliveryAddress, setPaymentIntentDeliveryAddress] =
    useState(null);
  const receiverFirstName = useRef(" ");
  const receiverInitial = useRef(null);
  const receiverLastName = useRef(null);
  const houseNo = useRef(null);
  const street = useRef(null);
  const townORcity = useRef(null);
  const stateOrProvince = useRef(null);
  const appartmentNo = useRef(null);
  const zipORareaCode = useRef(null);
  const country = useRef(null);
  const phone = useRef(null);
  const [val, setIPv4] = useState(null);
  const [deliveryAddressValidation, setDeliveryAddressValidation] =
    useState(" ");

  useEffect(() => {
    //
    if (basket.length === 0) {
      navigate("/Home"); // go to Home so you can add to basket
      return; // a neccesary line
      console.log("Basket for email : /n", basket);
    }
    // generate the special stripe secret which allows us to charge customer a new total price when basket items change
    //1.create async inside useEffect, to await axios fetches
    if (!user) {
      return;
    }
    const getClientSecret = async (request, response) => {
      axios
        .post(`http://localhost:5000/dollarealmsllc/us-central1/api/checkout`)
        .then((response) => {
          console.log("Server Responses:", response.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
      // this is a authentication to receive money
      setClientSecret(response.data.clientSecret); // <== hold the secrete in a session state at the front end
      setEmail([...basket]);
    };
    //2.call the async inside the useEffect
    getClientSecret();
  }, [basket]); // END OF useEffect when page loads/refreshes

  // console.log("THE SECRETE IS >>>", clientSecret);

  const handleSubmit = async (event) => {
    /* handles Stripe payment form submit button */
    event.preventDefault();
    // delivery data validation
    if (
      receiverFirstName.current.value.length <= 0 ||
      receiverInitial.current.value.length <= 0 ||
      receiverLastName.current.value.length <= 0 ||
      houseNo.current.value.length <= 0 ||
      street.current.value.length <= 0 ||
      townORcity.current.value.length <= 0 ||
      stateOrProvince.current.value.length <= 0 ||
      zipORareaCode.current.value.length <= 0 ||
      country.current.value.length <= 0 ||
      phone.current.value.length <= 0
    ) {
      alert(
        "Only apartment no is optional \n All other delivery fields are required .. "
      );
      setDeliveryAddressValidation(
        "Please enter all delivery information ! \n Only Apartment No is optional"
      );
      return;
    }
    // new code
    if (!stripe || !elements) {
      return;
    }

    // disables the Buy Now button during payment processing, to avoid double charge
    //
    setProcessing(true);
    // fetch('https://get-ip-only.herokuapp.com/')
  };

  const handleChange = (event) => {
    // Listens for changes in the CardElement
    // and displays any errors as the customer types their card details
    setDisabled(event.empty); // if no event took place disable Credit CardElement
    setError(event.error ? event.error.message : ""); // if any Credit CardElement error show error msg
  };
  /// we must programmatically prevent user from refreshing payment page for security reasons
  // here:
  return (
    <div key={user?.email} className="payment">
      <div
        style={{
          fontWeight: 100,
          color: "black",
          marginLeft: 1,
        }}
      >
        <h5>
          <br />
          <br />
          {/* on clicking link should navigate to checkout page for this number of items */}
          {/* Checking out ( <Link to="/checkout"> {basket?.length} items </Link>) */}
          Checking out ( <Link to="/payment2"> {basket?.length} items </Link>)
          <br />
        </h5>
        <div className="payment_title">
          <div>
            <h4>Enter delivery information :</h4>
            <br />
          </div>
        </div>
      </div>

      <form action="/payment2" method="post">
        <input type="submit" value="Proceed to Checkout" />
      </form>
    </div>
  );
}

export default Payment;
