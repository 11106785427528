import React from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";

const CheckoutButton = ({ sessionId }) => {
  const stripe = useStripe();
  const handleClick = async () => {
    try {
      const { error } = await stripe.redirectToCheckout({ sessionId });
      if (error) {
        console.error("Error redirecting to checkout:", error);
      }
    } catch (error) {
      console.error("Unexpected error:", error);
    }
  };

  return (
    <button onClick={handleClick} disabled={!stripe}>
      <h3>Proceed to Check out</h3>
    </button>
  );
};

export default CheckoutButton;
